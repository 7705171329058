import React, { useContext } from "react";
import { UserFragment } from "~/graphql/__generated";

interface IUserContext {
  user: IUser;
  tags: string[];
  theme: "light" | "dark" | "";
  setTheme: (theme: "light" | "dark") => void;
}

export interface IUser extends UserFragment {
  authenticated: boolean;
  source: string;
  profilePictureURL: string;
  displayName: string;
}
export const UserContext = React.createContext<IUserContext>({
  user: {
    authenticated: false,
    id: "",
    name: "",
    email: "",
    profilePicture: "",
    surveyFilled: false,
    isStaff: false,
    source: "mindstone",
    signupDate: 0,
    username: "",
    displayName: "",
    profilePictureURL: "",
    hasAddedResources: false,
  },
  theme: "light",
  tags: [],
  setTheme: (theme) => {},
});

export const useUser = () => useContext(UserContext).user;
export const useTags = () => useContext(UserContext).tags;
export const useDarkMode = () => useContext(UserContext).theme === "dark";
export const useThemeSetter = () => useContext(UserContext).setTheme;
